$icomoon-font-family		: "icomoon" !default;
$icomoon-font-path			: "../fonts/" !default;

$icon-linkedin           : "\e91d";
$icon-small-line         : "\e936";
$icon-doc                : "\e900";
$icon-code               : "\e901";
$icon-certif             : "\e902";
$icon-account            : "\e903";
$icon-add                : "\e904";
$icon-arrow-left         : "\e905";
$icon-arrow-right        : "\e906";
$icon-arrow_right        : "\e907";
$icon-cascad_logo-path1  : "\e908";
$icon-cascad_logo-path2  : "\e909";
$icon-cascad_logo-path3  : "\e90a";
$icon-cascad_logo-path4  : "\e90b";
$icon-cascad_logo-path5  : "\e90c";
$icon-cascad_logo-path6  : "\e90d";
$icon-cascad_logo-path7  : "\e90e";
$icon-cascad_logo-path8  : "\e90f";
$icon-cascad_logo-path9  : "\e910";
$icon-cascad_logo-path10 : "\e911";
$icon-cascad_logo-path11 : "\e912";
$icon-cascad_logo-path12 : "\e913";
$icon-cascad_logo-path13 : "\e914";
$icon-cascad_logo-path14 : "\e915";
$icon-cascad_logo-path15 : "\e916";
$icon-cascad_logo-path16 : "\e917";
$icon-cascad_logo-path17 : "\e918";
$icon-cascad_logo-path18 : "\e919";
$icon-cascad_logo-path19 : "\e933";
$icon-cascad_logo-path20 : "\e934";
$icon-cascad_logo-path21 : "\e935";
$icon-cascad_logo_1coul  : "\e91a";
$icon-checkbox           : "\e91b";
$icon-checkbox_filled    : "\e91c";
$icon-contact            : "\e91e";
$icon-download           : "\e91f";
$icon-dropdown           : "\e920";
$icon-eye                : "\e921";
$icon-facebook           : "\e922";
$icon-hec_logo           : "\e923";
$icon-help               : "\e924";
$icon-link               : "\e925";
$icon-play               : "\e926";
$icon-radio_empty        : "\e927";
$icon-radio_full         : "\e928";
$icon-search             : "\e929";
$icon-settings           : "\e92a";
$icon-share              : "\e92b";
$icon-success            : "\e92c";
$icon-supp               : "\e92d";
$icon-toggle_y-n         : "\e92e";
$icon-twitter            : "\e92f";
$icon-univ_orleans_logo  : "\e930";
$icon-upload             : "\e931";
$icon-warning            : "\e932";
$icon-cnrs_logo          : "\e937";





@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?ilvopg');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?ilvopg#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff2?ilvopg') format('woff2'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?ilvopg') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?ilvopg') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?ilvopg##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-cnrs_logo {
  &:before {
    content: $icon-cnrs_logo;     
    color: #fff;
  }
}
.icon-linkedin {
  &:before {
    content: $icon-linkedin;     
    color: #fff;
  }
}
.icon-small-line {
  &:before {
    content: $icon-small-line;     
    color: #fff;
  }
}
.icon-doc {
  &:before {
    content: $icon-doc;     
    color: #4ecb86;
  }
}
.icon-code {
  &:before {
    content: $icon-code;     
    color: #4ecb86;
  }
}
.icon-certif {
  &:before {
    content: $icon-certif;     
    color: #4ecb86;
  }
}
.icon-account {
  &:before {
    content: $icon-account; 
  }
}
.icon-add {
  &:before {
    content: $icon-add;     
    color: #fff;
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left; 
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right; 
  }
}
.icon-arrow_right {
  &:before {
    content: $icon-arrow_right;     
    color: #fff;
  }
}
.icon-cascad_logo .path1 {
  &:before {
    content: $icon-cascad_logo-path1;  
    color: rgb(3, 78, 205);
  }
}
.icon-cascad_logo .path2 {
  &:before {
    content: $icon-cascad_logo-path2;  
    margin-left: -3.6943359375em;  
    color: rgb(0, 0, 0);
  }
}
.icon-cascad_logo .path3 {
  &:before {
    content: $icon-cascad_logo-path3;  
    margin-left: -3.6943359375em;  
    color: rgb(0, 0, 0);
  }
}
.icon-cascad_logo .path4 {
  &:before {
    content: $icon-cascad_logo-path4;  
    margin-left: -3.6943359375em;  
    color: rgb(0, 0, 0);
  }
}
.icon-cascad_logo .path5 {
  &:before {
    content: $icon-cascad_logo-path5;  
    margin-left: -3.6943359375em;  
    color: rgb(0, 0, 0);
  }
}
.icon-cascad_logo .path6 {
  &:before {
    content: $icon-cascad_logo-path6;  
    margin-left: -3.6943359375em;  
    color: rgb(0, 0, 0);
  }
}
.icon-cascad_logo .path7 {
  &:before {
    content: $icon-cascad_logo-path7;  
    margin-left: -3.6943359375em;  
    color: rgb(0, 0, 0);
  }
}
.icon-cascad_logo .path8 {
  &:before {
    content: $icon-cascad_logo-path8;  
    margin-left: -3.6943359375em;  
    color: rgb(0, 0, 0);
  }
}
.icon-cascad_logo .path9 {
  &:before {
    content: $icon-cascad_logo-path9;  
    margin-left: -3.6943359375em;  
    color: rgb(0, 0, 0);
  }
}
.icon-cascad_logo .path10 {
  &:before {
    content: $icon-cascad_logo-path10;  
    margin-left: -3.6943359375em;  
    color: rgb(0, 0, 0);
  }
}
.icon-cascad_logo .path11 {
  &:before {
    content: $icon-cascad_logo-path11;  
    margin-left: -3.6943359375em;  
    color: rgb(0, 0, 0);
  }
}
.icon-cascad_logo .path12 {
  &:before {
    content: $icon-cascad_logo-path12;  
    margin-left: -3.6943359375em;  
    color: rgb(0, 0, 0);
  }
}
.icon-cascad_logo .path13 {
  &:before {
    content: $icon-cascad_logo-path13;  
    margin-left: -3.6943359375em;  
    color: rgb(0, 0, 0);
  }
}
.icon-cascad_logo .path14 {
  &:before {
    content: $icon-cascad_logo-path14;  
    margin-left: -3.6943359375em;  
    color: rgb(0, 0, 0);
  }
}
.icon-cascad_logo .path15 {
  &:before {
    content: $icon-cascad_logo-path15;  
    margin-left: -3.6943359375em;  
    color: rgb(0, 0, 0);
  }
}
.icon-cascad_logo .path16 {
  &:before {
    content: $icon-cascad_logo-path16;  
    margin-left: -3.6943359375em;  
    color: rgb(3, 78, 205);
  }
}
.icon-cascad_logo .path17 {
  &:before {
    content: $icon-cascad_logo-path17;  
    margin-left: -3.6943359375em;  
    color: rgb(9, 11, 68);
  }
}
.icon-cascad_logo .path18 {
  &:before {
    content: $icon-cascad_logo-path18;  
    margin-left: -3.6943359375em;  
    color: rgb(9, 11, 68);
  }
}
.icon-cascad_logo .path19 {
  &:before {
    content: $icon-cascad_logo-path19;  
    margin-left: -3.6943359375em;  
    color: rgb(9, 11, 68);
  }
}
.icon-cascad_logo .path20 {
  &:before {
    content: $icon-cascad_logo-path20;  
    margin-left: -3.6943359375em;  
    color: rgb(9, 11, 68);
  }
}
.icon-cascad_logo .path21 {
  &:before {
    content: $icon-cascad_logo-path21;  
    margin-left: -3.6943359375em;  
    color: rgb(9, 11, 68);
  }
}
.icon-cascad_logo_1coul {
  &:before {
    content: $icon-cascad_logo_1coul;     
    color: #4d4d4d;
  }
}
.icon-checkbox {
  &:before {
    content: $icon-checkbox; 
  }
}
.icon-checkbox_filled {
  &:before {
    content: $icon-checkbox_filled; 
  }
}
.icon-contact {
  &:before {
    content: $icon-contact; 
  }
}
.icon-download {
  &:before {
    content: $icon-download;     
    color: #034ecd;
  }
}
.icon-dropdown {
  &:before {
    content: $icon-dropdown; 
  }
}
.icon-eye {
  &:before {
    content: $icon-eye;     
    color: #cad0d3;
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook;     
    color: #fff;
  }
}
.icon-hec_logo {
  &:before {
    content: $icon-hec_logo;     
    color: #fff;
  }
}
.icon-help {
  &:before {
    content: $icon-help; 
  }
}
.icon-link {
  &:before {
    content: $icon-link;     
    color: #034ecd;
  }
}
.icon-play {
  &:before {
    content: $icon-play;     
    color: #fff;
  }
}
.icon-radio_empty {
  &:before {
    content: $icon-radio_empty; 
  }
}
.icon-radio_full {
  &:before {
    content: $icon-radio_full; 
  }
}
.icon-search {
  &:before {
    content: $icon-search; 
  }
}
.icon-settings {
  &:before {
    content: $icon-settings; 
  }
}
.icon-share {
  &:before {
    content: $icon-share;     
    color: #fff;
  }
}
.icon-success {
  &:before {
    content: $icon-success;     
    color: #4ecb86;
  }
}
.icon-supp {
  &:before {
    content: $icon-supp; 
  }
}
.icon-toggle_y-n {
  &:before {
    content: $icon-toggle_y-n; 
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter;     
    color: #fff;
  }
}
.icon-univ_orleans_logo {
  &:before {
    content: $icon-univ_orleans_logo;     
    color: #fff;
  }
}
.icon-upload {
  &:before {
    content: $icon-upload; 
  }
}
.icon-warning {
  &:before {
    content: $icon-warning;     
    color: #fff;
  }
}

