/*************************************************************/
/**************          ==  PANEL LOGIN/OUT  ==     *********/
/*************************************************************/
.log-wrapp {
	display				: none;
	position			: fixed;
	z-index				: 2;
	top					: 0;
	bottom				: 0;
	right				: 0;
	width				: 100%;
	max-width			: 86rem;
	min-width			: 50rem;
	padding				: 2rem 0 6rem;
	background-color	: $white;
	overflow			: auto;
	box-shadow			: 0 2.1rem 2.1rem rgba(0, 0, 0 , .07);

	.log-nav-box {
		padding			: 0 2rem;
		margin-bottom	: 10rem;

		.log-list-item {
			float		: left;
			padding		: 3rem;
			font-size	: 1.8rem;
			line-height	: 1;
			font-weight	: 500;
			color		: $grey;
			opacity		: .5;
			transition	: opacity .3s ease-in-out;
			cursor		: pointer;

			&:last-child {
				float			: right;
				text-decoration	: none;

				&:hover { text-decoration : none; }
			}

			& + .log-list-item { margin-left : 4rem; }

			&.active,
			&:hover { opacity : 1; text-decoration : underline; }
		}
	}

	.log-ctn-wrapp {
		display : none;
		padding : 0 5rem;

		.paper-alert { text-align : left; }

		&.active { display : block; }

		.log-title {
			font-size		: 3rem;
			font-weight		: 700;
			color			: $grey;
			margin-bottom	: 3rem;
		}

		.log-link {
			font-size	: 1.3rem;
			color		: $grey;
			line-height	: 1; 
			transition	: opacity .3s ease-in-out;
			padding		: 1rem 0;

			&:hover { opacity : .9; }
		}

		.log-reset-box		{
			margin-top	: -1.5rem;
			text-align	: left;
		}

		.log-btn-reset		{ margin-top	: -.9rem; } 
		.log-input-remember { margin-top	: 1rem; }

		.log-btn-reset,
		.log-input-remember,
		.paper-label-box { padding-left : 0; }

		.paper-form-box .btn { margin : 4rem auto; }
	}
}


// SKIN AUTO COMPLETE
.ui-menu {
	position			: absolute;
	z-index				: 111111111;
	width				: 75%;
	background-color	: $white;

	&.ui-autocomplete {
		max-height	: 20rem !important;
		overflow-y	: scroll;
	}
}

.ui-menu-item {
	border-radius	: 0 0 .3rem .3rem;
	background		: $white;

	&:nth-child(even) { background-color : $light-grey; }
}

.ui-menu-item-wrapper {
	height			: 5rem;
	line-height		: 5rem;
	font-size		: 1.6rem;
	width			: 100%;
	color			: $grey;
	padding			: 0 1.5rem;
	cursor			: pointer;
	transition		: color .3s ease-in-out;
	border-radius	: .3rem;
	overflow		: hidden;

	&:hover { color : $blue; }
}

.ui-menu-item {
	background-color	: #e6e6e6;
	border-radius		: .3rem;
}

.ui-helper-hidden-accessible { display : none; }