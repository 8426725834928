/*************************************************************/
/**************          ==  BTN  ==                **********/
/*************************************************************/
.hp-box {
	padding : 5rem 0 9rem;

	// GENERAL
	.hp-title {
		color		: $dark-blue;
		font-size	: 6rem;
		font-style	: italic;
		line-height	: 7rem;
	}

	.hp-logo {
		display		: block;
		max-width	: 42rem;
	}

	.hp-subtitle {
		font-size	: 4rem;
		font-weight	: 700;
		line-height	: 3rem;
		margin		: 4rem auto 0;
	}

	.hp-img { display : block; }

	.hp-txt {
		font-size	: 1.8rem;
		line-height	: 3rem;
		margin		: 3rem 0 0;
	}

	.hp-emphase { font-weight : 700; }

	.btn { margin : 4rem auto 0; }

	&.hp-ctn-head {
		background-color	: $white;
		min-height			: 70rem;
		position			: relative;

		.hp-txt {
			color		: $grey;
			max-width	: 40rem;
		}

		.hp-box-img {
			bottom		: -13rem;
			position	: absolute;
			right		: 0;
		}

		.hp-img {
			display	: block;
			height	: 82.1rem;
			width	: 113rem;
		}
	}

	&.hp-ctn-subhead {
		background-color	: $light-blue;
		text-align			: center;

		.hp-subtitle,
		.hp-txt { color : $grey; }

		.hp-txt {
			margin		: 3rem auto 0;
			max-width	: 73rem;
		}

		.hp-video {
			align-items			: center;
			background-position	: 100% 50%;
			background-repeat	: no-repeat;
			display				: flex;
			height				: 53rem;
			justify-content		: center;
			margin				: 3rem auto 0;
			width				: 101rem;
		}

		.hp-iframe {
			height	: 47.9rem;
			width	: 85rem;
		}

		p,
		li {
			color		: $grey;
			font-size	: 1.8rem;
			line-height	: 3rem;
			margin		: 3rem 0 0;
		}
	}

	&.hp-ctn1 {
		background-color	: $dark-blue;
		background-position	: 100% 50%;
		background-repeat	: no-repeat;
		background-size		: contain;
		
		.hp-subtitle,
		.hp-txt,
		p,
		li { color : $white; }

		.btn {
			background-color : $white;

			.btn-link { color : $black; padding : 1rem 3rem; }
		}

		p,
		li {
			font-size	: 1.8rem;
			line-height	: 3rem;
			margin		: 3rem 0 0;
		}
	}

	&.hp-ctn2 {
		background-color	: $white;
		background-position	: 22% 50%;
		background-repeat	: no-repeat;
		background-size		: contain;

		.lg7 { float : right; }

		.hp-subtitle,
		.hp-txt { color : $grey; }

		.btn {
			background-color : $blue;

			.btn-link { color : $white; padding : 1rem 3rem; }
		}

		p,
		li {
			color		: $grey;
			font-size	: 1.8rem;
			line-height	: 3rem;
			margin		: 3rem 0 0;
		}
	}

	&.hp-ctn3 {
		background-color	: $grey;
		background-position	: 100% 50%;
		background-repeat	: no-repeat;
		background-size		: contain;
		
		.hp-subtitle,
		.hp-txt { color : $white; }

		.btn {
			background-color : $blue;

			& + .btn { margin-left : 2rem; }

			.btn-link { color : $white;  padding : 1rem 3rem; }
		}

		p,
		li {
			color		: $white;
			font-size	: 1.8rem;
			line-height	: 3rem;
			margin		: 3rem 0 0;
		}
	}

	&.hp-ctn4 {
		background-color	: $dark-blue;
		background-position	: 25% 50%;
		background-repeat	: no-repeat;

		.lg7 { float : right; }
		
		.hp-subtitle,
		.hp-txt { color : $white; }

		.btn {
			background-color : $white;

			.btn-link { color : $black;  padding : 1rem 3rem; }
		}

		p,
		li {
			color		: $white;
			font-size	: 1.8rem;
			line-height	: 3rem;
			margin		: 3rem 0 0;
		}
	}

	&.hp-ctn5 {
		background-color	: $grey;

		.hp-subtitle,
		.hp-txt { color : $white; }

		.lg4 { text-align : center;  }

		.hp-box-icon {
			display		: inline-block;
			margin-top	: 7rem;
		}

		.icon {
			display		: inline-block;
			font-size	: 7rem;

			+ .icon { margin-left : 2rem; }
		}

		.btn .btn-link { padding : 1rem 3rem; }

		p,
		li {
			color		: $white;
			font-size	: 1.8rem;
			line-height	: 3rem;
			margin		: 3rem 0 0;
		}

		.hp-box-icon .menu-link + .menu-link { margin-left : 2rem; }
	}

	&.hp-ctn7 {
		text-align : center;

		.hp-subtitle,
		.hp-txt { text-align : left; }

		.btn .btn-link {
			padding : 1rem 3rem;
			text-align : center;
		}

		p,
		li {
		 	color		: $grey;
		 	font-size	: 1.8rem;
		 	line-height	: 3rem;
		 	margin	    : 3rem 0 0;
		 	text-align	: left;
		}
	}
}

.display-mobile { display : none !important; }

@media only screen and (max-width: 992px) {
	.display-desktop { display	: none !important; } 
	.display-mobile  { display	: block !important; }

	.hp-box {
		.hp-logo {
			margin-bottom	: 1rem;
			max-width		: 20rem;
		}

		.hp-title {
			font-size	: 3rem;
			line-height	: 4rem;
		}

		.hp-subtitle {
			font-size	: 3rem; 
			line-height	: 4rem;
		}

		.hp-txt {
			font-size	: 1.6rem;
			line-height	: 1.5;
		}

		&.hp-ctn-head {
			min-height	: initial;
			padding		: 4rem 0 5rem;

			.hp-img { 
				height	: 100%;
				margin	: 2rem 0;
				width	: 125%;
			}
		}

		&.hp-ctn-subhead {
			padding : 5rem 0;

			.sm12 { padding : 0; }

			.hp-subtitle { margin : 0 auto; }

			.hp-video {
				height			: 0;
				margin			: 3rem 0;
				padding-bottom	: 56.25%;
				padding-top		: 25px;
				position		: relative;

				iframe {
					height		: 100%;
					left		: 0;
					position	: absolute;
					top			: 0;
					width		: 100%;
				}
			}
		}

		&.hp-ctn1,
		&.hp-ctn2,
		&.hp-ctn3,
		&.hp-ctn4 { background-position : 0 0; }

		&.hp-ctn1 {
			background-image	: url('../img/hp-c1-bg1-mobile.jpg') !important;
			padding				: 10rem 0 5rem;
		}

		&.hp-ctn2 { padding : 10rem 0 5rem; }

		&.hp-ctn3 {
			padding : 11rem 0 5rem;

			.hp-subtitle { margin : 14rem auto 0; }
		}

		&.hp-ctn4 { display : none; }

		&.hp-ctn5 .hp-subtitle { margin-top : 0; }

		&.hp-ctn7 {
			padding : 5rem 0;
			.hp-subtitle { margin : 0 auto; }
		}

		.certif-list {
			.certif-content {
				display      : block;
				height       : auto;
				padding      : 1rem;
				table-layout : auto;
			}

			.certif-cell {
				&:nth-child(1),
				&:nth-child(2) { display : inline-block; }

				&:nth-child(1) { width : 7.6rem; }

				&:nth-child(2) {
					width : calc(100% - 10rem);

					.certif-txt {
						display : table;
						height  : 7.6rem;
						margin  : 0;

						.certif-txt-item {
							display			: table-cell;
							vertical-align	: middle;
						}
					}
				}

				&:nth-child(3),
				&:nth-child(4) {
					width   : 100%;
					display : block;
				}

				&:last-child { display : none; }
			}
		}
	}
}
