/*************************************************************/
/**************        ==  CMS SIMPLE  ==       **************/
/*************************************************************/
.cms-simple-wrap {

	.cms-simple-head {
		margin-top	: 4rem ;
		text-align	: center;
	}

	.cms-simple-title {
		font-size	: 4rem;
		font-weight	: 700;
		line-height	: 1;
		color		: $grey;
	}

	.cms-simple-chapo {
		font-size	: 1.8rem;
		line-height	: 2;
		color		: $grey;
		margin		: 3rem 0 0;
	}

	.cms-simple-img { 
		display	: inline-block;
		margin	: 3rem 0 0;
		width	: 100%;
		height	: auto;
	}
	
	.cms-simple-ctn {
		margin : 9rem 0 5rem;

		&.cms-simple-ctn-adjust { margin : 3rem 0 5rem; }

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			font-size		: 2.4rem;
			line-height		: 1.3;
			color			: $grey;
			margin-bottom	: 2rem;
		}

		p {
			font-size		: 1.8rem;
			line-height		: 1.3;
			color			: $grey;
			margin-bottom	: 2rem;

			// & + p { margin-top : 1rem; }
			a {
				display			: inline-block;
				font-size		: 1.8rem;
				line-height		: 1.3;
				color			: $grey;
				font-weight		: 700;
				text-decoration	: underline;
			}
		}
		
		strong { font-weight	: 700; }
		em     { font-style		: italic; }

		ul {
			list-style		: initial;
			padding-left	: 2rem;
			margin-bottom	: 2rem;
		}

		li {
			font-size		: 1.8rem;
			line-height		: 1.3;
			color			: $grey;
			margin-bottom	: 1rem;

		}

		ol {
			counter-reset	: item;
			margin-left		: 0;
			padding-left	: 0;
			margin-bottom	: 2rem;

			li {
				display			: block;
				margin-bottom	: .5rem;
				margin-left		: 2rem;

				&:before {
					display				: inline-block;
					content				: counter(item) ". ";
					counter-increment	: item;
					width				: 2rem;
					margin-left			: -2rem;
				}
			}
		}

	}
}



/*************************************************************/
/**************        ==  CMS COMPLEX  ==       **************/
/*************************************************************/
.cms-complex-wrap {

	header.banner-box {
		padding	: 10rem 0 5rem 0;
	}

	.banner-title {
		font-size	: 4rem;
		font-weight	: 700;
		line-height	: 1;
	}

	.banner-txt,
	.banner-txt p {
		font-size	: 1.8rem;
		line-height	: 2;
		margin		: 3rem 0;
	}

	.banner-txt {
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			font-size		: 2.4rem;
			line-height		: 1.3;
			color			: $grey;
			margin-bottom	: 2rem;
		}

		p a {
				display			: inline-block;
				font-size		: 1.8rem;
				line-height		: 1.3;
				color			: $grey;
				font-weight		: 700;
				text-decoration	: underline;
		}
		
		strong { font-weight	: 700; }
		em     { font-style		: italic; }

		ul {
			list-style		: initial;
			padding-left	: 2rem;
			margin-bottom	: 2rem;
		}

		li {
			font-size		: 1.8rem;
			line-height		: 1.3;
			color			: $grey;
			margin-bottom	: 1rem;

		}

		ol {
			counter-reset	: item;
			margin-left		: 0;
			padding-left	: 0;
			margin-bottom	: 2rem;

			li {
				display			: block;
				margin-bottom	: .5rem;
				margin-left		: 2rem;

				&:before {
					display				: inline-block;
					content				: counter(item) ". ";
					counter-increment	: item;
					width				: 2rem;
					margin-left			: -2rem;
				}
			}
		}
	}

	.banner-img {
		display	: block;
		margin	: 0;
		width	: 100%;
		height	: auto;
	}


	.banner-box {
		padding	: 10rem 0;
		color	: $grey;

		&.banner-center { text-align	: center; }
		&.banner-side   { text-align	: left; }

		&.with-lightblue-bg { background-color	: $light-blue; }
		&.with-white-bg     { background-color	: $white; }
	}

	.banner-unbox {
		position	: relative;
		padding		: 10rem 0;

		&.banner-left .banner-bg {
			left	: 0;
			right	: 62.5%;
		}

		&.banner-right .banner-bg {
			left	: 62.5%;
			right	: 0;
		}

		&.with-darkblue-bg {
			color				: $white;
			background-color	: $dark-blue;

				ul,
				li,
				p { color : $white; }
		}

		&.with-lightblue-bg {
			color				: $grey;
			background-color	: $light-blue;
		}

		.banner-title,
		.banner-txt { text-align : left; }

		.banner-bg {
			position			: absolute;
			top					: 0;
			bottom				: 0;
			background-size		: cover;
			background-position	: 50% 50%;
		}
	}

	.btn { margin-top : 2rem; }
}