/*************************************************************/
/**************          ==  HEADER  ==         **************/
/*************************************************************/
.header-main {
	background-color	: $white;
	box-shadow			: 0 2.1rem 2.1rem rgba(0, 0, 0 , .1);
	left				: 0;
	position			: fixed;
	right				: 0;
	
	text-align			: center;
	top					: 0;
	width				: 100%;
	z-index				: 1;

	.menu-wrapp {
		display : inline-block;

		.menu-box {
			display			: inline-block;
			padding			: 0 3.5rem;
			position		: relative;
			text-align		: center;
			vertical-align	: middle;

			@media (max-width:1500px) {
				padding : 0 1rem;
			}

			&:first-child { padding-left : 0; }
			&:last-child  { padding-right : 0; }

			&:hover > .menu-link {
				text-decoration : underline;

				& + .menu-sublist {
					left	: -15%;
					opacity	: 1;
					z-index	: 5;
				}
			}
		}

		.menu-link {
			color		: $grey;
			font-size	: 1.8rem;
			font-weight	: 500;
			line-height	: 1;
			padding		: 5.1rem 0 3rem;
			position	: relative;
			transition	: opacity .3s ease-in-out;

			@media (max-width:1500px) {
				font-size	: 1.7rem;
			}

			&:hover { opacity : .6; }

			.icon { display : none; }
		}

		.menu-sublist {
			background-color	: $white;
			border-radius		: .3rem;
			box-shadow			: .1rem -.3rem 5rem rgba(0, 0, 0, .1);
			left				: -999rem;
			margin-top			: -.2rem;
			opacity				: 0;
			position			: absolute;
			text-align			: left;
			transition			: opacity .3s ease-in-out;
			width				: 130%;
			z-index				: -10;

			@media (max-width: 991px) {
				display : none;
			}

			&:before {
				border-color	: transparent transparent $white transparent;
				border-style	: solid;
				border-width	: 0 1rem 1rem 1rem;
				content			: "";
				height			: 0;
				left			: 50%;
				position		: absolute;
				top				: -.9rem;
				transform		: translateX(-50%);
				width			: 0;
			}
		}

		.menu-sublist-item {
			padding : .5rem;

			.menu-link {
				font-weight	: 400;
				padding		: 1.5rem;
				transition	: background-color .3s ease-in-out;
				will-change	: background-color;

				&:hover {
					background-color	: $light-blue;
					opacity				: 1;
				}
			}
		}
	}

	.new-menu-wrapp { display : none; }

	&.header-main-hp {
		box-shadow	: none;
		position	: relative;
	}

	.logo-main {
		.logo-link {  display : inline-block; }

		.logo-img { 
			display	: block;
			height	: 4.2rem;
			width	: 17.4rem;
		}
	}
}

.mobile-head { display : none; }

.submenu-box {
	display		: none;
	left		: -33%;
	position	: absolute;
	right		: 0;
	width		: 200%;

	&:before {
		border-color	: transparent transparent $white transparent;
		border-style	: solid;
		border-width	: 0 1rem 1rem 1rem;
		content			:"";
		height			: 0;
		left			: 50%;
		margin-left		: -1rem;
		position		: absolute;
		top				: -.9rem;
		width			: 0;
	}

	.submenu-list {
		background-color	: $white;
		border-radius		: .3rem;
		box-shadow			: .1rem -.3rem 5rem rgba(0, 0, 0, .1);
		overflow			: hidden;
		padding				: .5rem;
	}

	.submenu-list-item {
		&:first-child { border-radius : .3rem .3rem 0 0; }
		&:last-child  { border-radius : 0 0 .3rem .3rem; }
	}

	.submenu-link {
		background-color	: $white;
		color				: $grey;
		display				: block;
		font-size			: 1.8rem;
		line-height			: 1;
		padding				: 1.5rem;
		text-align			: left;
		transition			: background-color .3s ease-in-out;
		will-change			: background-color;

		&:hover { background-color : $light-blue;}
	}
}

// .main-content { overflow : hidden; }

@media only screen and (min-width: 1320px) {
	.header-main .header-main-nav { width : 150rem; }
}

@media only screen and (max-width: 992px) {
	.main-content { padding-top : 7rem; }
	.main-content-desktop { margin : 0 0 3rem }

	.header-main {
		box-shadow	: none;
		position	: absolute;
		
		&.header-main-hp {
			position : absolute;

			.logo-main { display : none; }
		}

		.header-main-nav {
			background-color	: $grey;
			display				: none;
			padding				: 2rem 3rem 3rem;
			width				: 100%;
		}

		.menu-wrapp {
			display : block;

			&:nth-child(1) .menu-box:nth-child(1) { display : none; }

			.menu-box {
				display		: block;
				margin-top	: 3rem;
				text-align	: left;
				width		: 100%;
			}

			.menu-link {
				color	: $white;
				padding	: 1rem 0;
			}

			.menu-sublist {
				background-color	: $grey;
				border-radius		: 0;
				box-shadow			: none;
				left				: auto;
				position			: relative;
				right				: auto;
				width				: 100%;

				&:before { display : none; }
			}

			.menu-sublist-item {
				background-color	: $grey;
				margin-top			: 1rem;

				&:nth-child(even) { background-color : $grey; }

				.menu-link {
					font-size	: 1.6rem;
					padding		: 1rem 0;
				}
			}

			.link-with-sublist {
				.icon {
					display		: block;
					font-size	: .1rem;
					position	: absolute;
					right		: 0;
					top			: 50%;
					transform	: translateY(-50%);

					&:before { color : $white; }
				}

				&.active .icon:before {
					content		: "\e920";
					font-size	: .5rem;
				}
			}

			.menu-link-log {
				background-color	: $white;
				border-radius		: .3rem;
				color				: $grey;
				margin				: 0 auto;
				text-align			: center;
				width				: 18rem;
			}
		}

		.new-menu-wrapp1 {
			display : block;
			opacity : .3;

			.menu-box {
				margin-top: 2rem;

				&:nth-child(3):after {
					background-color	: #fff;
					content				: "";
					display				: block;
					height				: .1rem;
					margin-top			: 2rem;
					width				: 3rem;
				}
			}

			.menu-link { font-weight : 400; }
		}

		.new-menu-wrapp2 {
			display		: table;
			margin		: 2rem auto;
			max-width	: 26rem;

			.menu-box {
				display		: table-cell;
				text-align	: center;
			}

			.menu-link .icon {
				display : block;

				&:before { font-size : 3rem; }
			}
		}
	}

	.mobile-head {
		background-color	: $white;
		display				: table;
		height				: 6.8rem;
		padding				: 0 3rem;
		width				: 100%;

		&.active {
			background-color : $grey;

			.head-box { text-align : right; }

			.logo-main {
				.logo-img.logo-img-color { display : none; }
				.logo-img.logo-img-bw    { display : block; }
			}
		}

		.head-box {
			display			: table-cell;
			text-align		: center;
			vertical-align	: middle;
			width			: 2rem;

			+ .head-box { width : calc(100% - 2rem); }
		}

		.burger-menu {
			cursor	: pointer;
			display	: inline-block;

			.steak {
				background-color	: $grey;
				border-radius		: .1rem;
				display				: block;
				height				: .2rem;
				margin				: .6rem 0;
				transition			: transform .3s;
				width				: 2rem;

				&:nth-child(3) { width : 1.4rem; }
			}

			.icon {
				display : none;

				&:before { color : $white; }
			}
		}

		.logo-img {
			display		: block;
			height		: auto;
			margin		: 0 auto;
			max-width	: 12rem;

			&.logo-img-color { display : block; }
			&.logo-img-bw	 { display : none; }
		}
	}
}