.roller-loader {
	position	: relative;
	display		: block;
	width		: 6.4rem;
	height		: 6.4rem;

	.roller-point {
		animation			: rollerloader 1.2s cubic-bezier(.5, 0, .5, 1) infinite;
		transform-origin	: 3.2rem 3.2rem;

		&:after {
			content			: " ";
			display			: block;
			position		: absolute;
			width			: .6rem;
			height			: .6rem;
			border-radius	: 50%;
			background		: $blue;
			margin			: -.3rem 0 0 -.3rem;
		}

		&:nth-child(1) { animation-delay : -.036s; }
		
		&:nth-child(1):after {
			top		: 5rem;
			left	: 5rem;
		}

		&:nth-child(2) { animation-delay : -.072s; }
		
		&:nth-child(2):after {
			top		: 5.4rem;
			left	: 4.5rem;
		}
		
		&:nth-child(3) { animation-delay : -.108s; }
		
		&:nth-child(3):after {
			top		: 5.7rem;
			left	: 3.9rem;
		}
		
		&:nth-child(4) { animation-delay : -.144s; }
		
		&:nth-child(4):after {
			top		: 5.8rem;
			left	: 3.2rem;
		}
		
		&:nth-child(5) { animation-delay : -.18s; }
		
		&:nth-child(5):after {
			top		: 5.7rem;
			left	: 2.5rem;
		}
		
		&:nth-child(6) { animation-delay : -.216s; }
		
		&:nth-child(6):after {
			top		: 5.4rem;
			left	: 1.9rem;
		}
		
		&:nth-child(7) { animation-delay : -.252s; }
		
		&:nth-child(7):after {
			top		: 5rem;
			left	: 1.4rem;
		}
		
		&:nth-child(8) { animation-delay : -.288s; }
		
		&:nth-child(8):after {
			top		: 4.5rem;
			left	: 1rem;
		}
	}
}

@keyframes rollerloader {
	0%   { transform	: rotate(0); }
	100% { transform	: rotate(360deg); }
}