/*************************************************************/
/**************          ==  GRADE  ==   *********************/
/*************************************************************/

// DES P'TITES BUBULLES
.grade-circle {
	display			: block;
	height			: 5.6rem;
	width			: 5.6rem;
	font-size		: 1.6rem;
	font-weight		: 700;
	line-height		: 5.6rem;
	text-align		: center;
	text-transform	: uppercase;
	border-radius	: 50%;
	margin			: 0;

	&.circle-green {
		color				: $white;
		background-color	: $green; 
	}

	&.circle-red {
		color				: $white;
		background-color	: red; 
	}
}
// END - DES P'TITES BUBULLES

// LIST
.grade-list-wrapp { margin : -11rem 0 7rem 0; }

.grade-list-box {
	position	: relative;
	display		: inline-block;

	.grade-list {
		display			: inline-block;
		vertical-align	: middle;
		color			: $white;
		font-weight		: 700;
		opacity			: .2;
		transition		: opacity .3s ease-in-out;

		& + .grade-list { margin-left : 1.5rem; }

		&.active {
			opacity : 1;

			.grade-list-txt { display : block; }

			.grade-list-score {
				width				: 12.1rem;
				height				: 12.1rem;
				line-height			: 12.1rem;
				font-size			: 3.8rem;
				background-color	: $green;
				border-color		: $green;
			}
		}

		&:nth-child(1) .grade-list-txt { left : 12.1rem; }
		&:nth-child(2) .grade-list-txt { left : 18.6rem; }

		&:nth-child(3) .grade-list-txt {
			left		: 0;
			right		: 0;
			margin-top	: 1rem;
			text-align	: center;
		}

		&:nth-child(4) .grade-list-txt { right : 18.6rem; }
		&:nth-child(5) .grade-list-txt { right : 12.1rem; }
	}

	.grade-list-score {
		display			: block;
		width			: 5rem;
		height			: 5rem;
		font-size		: 1.5rem;
		line-height		: 5rem;
		text-align		: center;
		text-transform	: uppercase;
		border-radius	: 50%;
		border			: .1rem solid $white;
	}

	.grade-list-txt {
		position	: absolute;
		display		: none;
		margin-top	: -2rem;
		font-size	: 1.5rem;
		line-height	: 1;
		text-align	: left;
		color		: $green;
	}
}
// END - LIST