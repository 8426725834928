
/**********************************************************************/
/************          ==  CUSTOM INPUT ==          *******************/
/**********************************************************************/

.custom-select {
	position : relative;

	&.disabled > span,
	&.disabled > label .label { 
		cursor	: not-allowed; 
		opacity	: .5; 
	}

	& > select { display : none; }

	& > span {
		position		: relative;
		float			: left;
		width			: 75%;
		z-index			: 1;
		font-size		: 1.6rem;
		height			: 5rem;
		padding			: 0 4.5rem 0 2rem;
		line-height		: 5rem;
		color			: rgba(34, 34, 34, .5);
		overflow		: hidden;
		text-overflow	: ellipsis;
		white-space		: nowrap;
		background		: $white;
		border			: .1rem solid $grey;
		border-radius	: .3rem;
		cursor			: pointer;

		&:hover { color : #1c2c33; }

		&.placeholder-like { color : $grey; }
	}

	&.disabled > span { cursor	: not-allowed }

	& > span:after {
		content				: "\e920";
		color				: $grey;
		position			: absolute;
		right				: 2rem;
		top					: 50%;
		display				: block;
		font-size			: 5px;
		font-family			: 'icomoon';
		margin-top			: -2.6rem;
		opacity				: 1;
		transform-origin	: 50% 50%;
		transition			: all .2s ease;
		cursor				: pointer;
	}

	& > span.open:after { 
		transform	: rotate(180deg);
		opacity		: 1;
	}

	& > ul {
		position		: absolute;
		width			: 75%;
		left			: 25% !important;
		background		: $white;
		border			: .1rem solid $grey;
		border-radius	: .3rem;
		opacity			: 0;
		pointer-events	: none;
		font-family		: $main-font;
		margin			: -.1rem 0 0 0;
		max-height		: 35rem;
		overflow-y		: auto;
		overflow-x		: hidden;
		padding			: 0;
		transition		: all .3s ease;
		z-index			: 10;

		li:first-child { color : rgba(34, 34, 34, .5); }
	}
}

.panel-content .custom-select > ul            { padding		: 0; }
.no-csspointerevents .custom-select > ul      { display		: none; }
.custom-select > ul.small                     { font-size	: 70%; }
.custom-select > ul.open                      { opacity		: 1; pointer-events: auto; }
.no-csspointerevents .custom-select > ul.open { display		: block; }

.custom-select {
	& > ul li {
		display			: block;
		height			: 5rem;
		line-height		: 5rem;
		color			: $grey;
		padding			: 0 1.5rem;
		font-size		: 1.6rem;
		list-style-type	: none;
		cursor			: pointer;
	}

	& > ul li:hover,
	& > ul li.selected { background-color : #eaeaea }

	& > ul li input {
		height	: 3rem;
		width	: 100%;
	}

	& > label {
		display			: inline-block;
		font-size		: 1.4rem;
		margin-bottom	: 1rem;
	}
}


/***************          ==  CustomCheckbox ==          ***************/
.custom-checkbox {
	position : relative;

	&:before,
	&:after {
		font-family	: 'icomoon';
		display		: block;
		position	: absolute;
		top			: 50%;
		left		: 0;
		font-size	: 1.8rem;
		color		: $grey;
		transform	: translateY(-50%);
		cursor		: pointer;
	}

	& input {
		opacity		: 0;
		position	: absolute;
		top			: 0;
		left		: 0;
		width		: 2rem;
		height		: 100%;
		z-index		: 3;
		margin		: 0;
		cursor		: pointer;
	}
	
	&:before {
		content	: "\e91b";
		z-index	: 1;
	}

	&:after {
		content		: "\e91c";
		z-index		: 2;
		opacity		: 0;
		transition	: opacity .2s ease;
	}

	& label {
		padding-left	: 3rem;
		cursor			: pointer;
	}

	&.checked:after { opacity : 1; }
}


.custom-radio {
	display	: inline-block;
	margin	: 0 1rem 1rem 0 ;

	.custom-input:checked,
	.custom-input:not(:checked) {
		position	: absolute;
		left		: -999rem;

		&:checked + .custom-label,
		&:not(:checked) + .custom-label {
			display			: inline-block;
			position		: relative;
			padding-left	: 3rem;
			line-height		: 2rem;
			color			: $grey;
			cursor			: pointer;
		}

		&:checked + .custom-label:before,
		&:not(:checked) + .custom-label:before {
			content			: '';
			position		: absolute;
			left			: 0;
			top				: 0;
			width			: 2rem;
			height			: 2rem;
			border-radius	: 100%;
			border			: .1rem solid $grey;
			background		: $white;
		}

		&:checked + .custom-label:after,
		&:not(:checked) + .custom-label:after {
			content			: '';
			position		: absolute;
			width			: 1.2rem;
			height			: 1.2rem;
			background		: $grey;
			top				: .4rem;
			left			: .4rem;
			border-radius	: 100%;
			transition		: all .2s ease;
		}

		&:not(:checked) + .custom-label:after {
			opacity		: 0;
			transform	: scale(0);
		}

		&:checked + .custom-label:after {
			opacity		: 1;
			transform	: scale(1);
		}
	}
}


CUSTOM FILE
.xCustom-file {
	// overflow	: hidden;
	position	: relative;

	.paper-file,
	.action {
		width	: 100%;
		height	: 5rem;
	}

	.paper-file {
		position	: absolute;
		bottom		: 0;
		right		: 0;
		opacity		: 0;
		cursor		: pointer;
	}

	.filename {
		float			: left;
		border			: .1rem solid #e5e5e7;
		border-radius	: 3rem 0 0 3rem;
		font-size		: 14px;
		height			: 38px;
		line-height		: 36px;
		color			: #aeb2b6;
		overflow		: hidden;
		font-style		: italic;
		padding			: 0 1.5rem;
		text-overflow	: ellipsis;
		white-space		: nowrap;
		width			: 70%;
	}

	.action {
		float		: left;
		padding		: 0 1.5rem;
		font-size	: 1.6rem;
		line-height	: 5rem;
		color		: #bcbcbc;
		text-align	: left;

		.icon {
			float		: right;
			line-height	: 5rem;

			&:before { color : #bcbcbc; }
		}
	}
}


.paper-file-box {
	&.disabled {
		opacity: 0.4;
	}
	float : left;
	width : 75%;
}

.custom-file {
	// overflow : hidden;
	position : relative;

	> input {
		position	: absolute;
		z-index		: 1;
		left		: 0;
		width		: 100%;
		height		: 5rem;
		opacity		: 0;
		cursor		: pointer;
	}

	.filename { display : none; }

	.action {
		display			: block;
		width			: 100%;
		padding			: 0 1.5rem;
		font-size		: 1.6rem;
		line-height		: 5rem;
		color			: #bcbcbc;
		text-align		: left;
		border-radius	: .3rem;
		border			: .1rem solid $grey;

		&:after {
			content				: "";
			position			: absolute;
			right				: 2rem;
			top					: 50%;
			transform			: translateY(-50%);
			width				: 1.4rem;
			height				: 1.6rem;
			background-position	: 50% 50%;
			background-size		: cover;
			background-image	: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9Ijg0MyIgaGVpZ2h0PSIxMDI0IiB2aWV3Qm94PSIwIDAgODQzIDEwMjQiPiAgICA8cGF0aCBmaWxsPSIjMjIyIiBkPSJNMjQwLjk0MSA3ODMuMDU5aDM2MS40MTJ2LTM2MS40MTJoMjQwLjk0MWwtNDIxLjY0Ny00MjEuNjQ3LTQyMS42NDcgNDIxLjY0N2gyNDAuOTQxdjM2MS40MTJ6TTAgOTAzLjUyOWg4NDMuMjk0djEyMC40NzFoLTg0My4yOTR2LTEyMC40NzF6Ij48L3BhdGg+PC9zdmc+);
		}

		&.active {
			color : $grey;

			&:after { display : none; }
		}
	}

	.special {
		height : 100%;

		+ .filename {
			display			: block;
			width			: 100%;
			min-height		: 5rem;
			line-height		: 5rem;
			padding			: 0 1.5rem;
			border-radius	: .3rem;
			border			: .1rem solid $grey;
			background		: $white;
			cursor			: pointer;

			+ .action { display : none; }
		}
	}

	.special-drop-wrap {
		display			: table;
		border-spacing	: 10rem 4rem;
		width			: 100%;
		cursor			: pointer;

		.special-drop-box {
			position		: relative;
			display			: table-cell;
			vertical-align	: middle;
			padding-left	: 2rem;
		}

		.icon {
			position			: absolute;
			top					: -1rem;
			left				: -4rem;
			display				: block;
			width				: 4.4rem;
			height				: 4.4rem;
			border-radius		: 50%;
			background-color	: $green;

			&:after {
				content			: "+";
				position		: absolute;
				top				: 50%;
				left			: 50%;
				transform		: translate(-50%, -50%);
				display			: inline-block;
				color			: $white;
				font-size		: 3rem;
				width			: 4.4rem;
				height			: 4.4rem;
				line-height		: 4.2rem;
				text-align		: center;
				font-weight		: 100;
				border-radius	: 50%;
			}
		}

		.title {
			font-size	: 2.5rem;
			margin		: 0 0 .5rem;
		}

		.title,
		.txt {
			display		: block;
			line-height	: 1.3;
			color		: $grey;
		}

		.txt { font-size : 1.6rem; }
	}
}


@media(max-width: 480px) {
	.custom-checkbox.inline label { max-width : 75% }
}
