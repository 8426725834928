// ================================ //
// •••••••••••••••••••••••••••••••• //
// •••••••••••••••••••••••••••••••• //
//             ❯ CENTER             //
// •••••••••••••••••••••••••••••••• //
// •••••••••••••••••••••••••••••••• //

.center {
	position	: absolute;
	top			: 50%;
	left		: 50%;
	transform	: translate(-50%, -50%);
}


.valign-middle,
.valign-bottom,
.valign-top { font-size : 0 }

.valign-middle > *,
.valign-bottom > *,
.valign-top    > * {
	float		: none;
	display		: inline-block;
	font-size	: 1.6rem;
}

.valign-middle > * { vertical-align	: middle; }
.valign-bottom > * { vertical-align	: bottom }
.valign-top    > * { vertical-align	: top }