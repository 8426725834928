// RIPPLE OUT HOVER EFFECT
@keyframes hvr-ripple-out {
	100% {
		top		: -1.2rem;
		right	: -1.2rem;
		bottom	: -1.2rem;
		left	: -1.2rem;
		opacity	: 0;
	}
}

.hvr-ripple-out {
	display			: inline-block;
	vertical-align	: middle;
	transform		: perspective(.1rem) translateZ(0);
	box-shadow		: 0 0 .1rem rgba(0, 0, 0, 0);
	position		: relative;

	&:before {
		content				: '';
		position			: absolute;
		border-radius		: 50%;
		top					: 0;
		right				: 0;
		bottom				: 0;
		left				: 0;
		animation-duration	: 1s;
	}

	&:hover:before,
	&:focus:before,
	&:active:before { animation-name : hvr-ripple-out; }
}

// RIPPLE IN HOVER EFFECT
@keyframes hvr-ripple-in {
	100% {
		top		: 0;
		right	: 0;
		bottom	: 0;
		left	: 0;
		opacity	: 1;
	}
}

.hvr-ripple-in {
	display			: inline-block;
	vertical-align	: middle;
	transform		: perspective(.1rem) translateZ(0);
	box-shadow		: 0 0 .1rem rgba(0, 0, 0, 0);
	position		: relative;

	&:before {
		content				: '';
		position			: absolute;
		border-radius		: 50%;
		top					: -1.2rem;
		right				: -1.2rem;
		bottom				: -1.2rem;
		left				: -1.2rem;
		opacity				: 0;
		animation-duration	: 1s;
	}

	&:hover:before,
	&:focus:before,
	&:active:before { animation-name : hvr-ripple-in; }
}


// RIPPLE OUT & IN COLOR BORDER
.hvr-ripple-out,
.hvr-ripple-in {
	&.hvr-ripple-green:before { border : $green solid .3rem; }
	&.hvr-ripple-red:before   { border : $red solid .3rem; } 
}


// Underline From Left
.hvr-underline-from-left {
	display			: inline-block;
	vertical-align	: middle;
	transform		: perspective(.1rem) translateZ(0);
	box-shadow		: 0 0 .1rem rgba(0, 0, 0, 0);
	position		: relative;
	overflow		: hidden;

	&:before {
		content						: "";
		position					: absolute;
		z-index						: -1;
		left						: 0;
		right						: 100%;
		bottom						: 0;
		background					: $grey;
		height						: .2rem;
		transition-property			: right;
		transition-duration			: .3s;
		transition-timing-function	: ease-out;
	}

	&:hover:before,
	&:focus:before,
	&.active:before,
	&:active:before { right : 0; }
}

