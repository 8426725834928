/*************************************************************/
/************        ==  HEADER CONTENT  ==       ************/
/*************************************************************/

// NAV
.account-nav {
	margin : 2rem auto 4rem;

	.account-nav-list-item {
		float : left;

		& + .account-nav-list-item { margin-left : 6rem; }
	}

	.account-nav-link {
		height      : 4rem;
		line-height : 4rem;
		font-size   : 1.6rem;
		color       : $grey;
	}

	.icon { margin-right : 1rem; }
}
// END - NAV


// HEAD
.account-head {
	background-color : $light-blue;

	.account-head-wrap {
		position	: relative;
		display		: table;
		width		: 100%;
		height		: 23.1rem;
	}

	.account-head-box {
		display			: table-cell;
		width			: 50%;
		height			: 100%;
		vertical-align	: middle;
	}

	.account-head-title {
		font-size		: 5rem;
		line-height		: 1;
		color			: $grey;
		text-transform	: capitalize;
	}

	.account-head-subtitle {
		font-size	: 1.6rem;
		font-style	: italic;
		line-height	: 1;
		color		: $grey;
		margin-top	: 1rem;
	}

	.btn {
		position	: absolute;
		right		: 3rem;
		margin-top	: -1rem;
	}
}
// END - HEAD

.account-error {
	font-size	: 1.2rem;
	line-height	: 1;
	color		: $red;
	padding		: 2rem 0 1rem;
}

.account-title {
	font-size	: 3rem;
	line-height	: 1;
	color		: $grey;
	font-weight	: 700;
	margin		: 0 0 3rem;
}

/*************************************************************/
/**************       ==  CERTIFICATION   ==   ***************/
/*************************************************************/

// LIST CERTIFICATION
.certif-list {
	margin-top  : 4rem;
	text-align  : left;

	.certif-row + .certif-row { margin-top : 1rem; }

	.certif-content {
		display				: table;
		table-layout		: fixed;
		width				: 100%;
		height				: 8rem;
		background-color	: $light-blue;

		// @media (max-width: 992px) {
		// 	display      : block;
		// 	height       : auto;
		// }

		&:hover .certif-link { color : $black; }
	}

	.certif-cell {
		display			: table-cell;
		vertical-align	: middle;
		height			: 100%;
		padding			: 1rem;
		float			: none;

		// @media (max-width: 992px) {
		// 	&.certif-cell1 { display : inline-block; }
		// 	&.certif-cell2 { display : inline-block; }
		// 	&.certif-cell3 { display : block; }
		// 	&.certif-cell4 { display : block; }
		// 	&.certif-cell5 { display : none; }
		// }

		&.certif-cell1 .grade-circle	{ margin		: 0 auto; }
		&.certif-cell3					{ font-weight	: 700; }
		&.certif-cell4					{ font-style	: italic; }

		&.certif-cell5 {
			border-left	: .1rem solid $white;
			text-align	: center;
		}

		.grade-circle {
			text-align  : center;
			line-height : 5.6rem;
		}
	}

	.certif-box {
		display	: table;
		width	: 100%;
		height	: 100%;
	}

	.certif-txt {
		font-size	: 1.6rem;
		line-height	: 1;
		color		: $grey;
		margin		: 0;
	}

	.certif-txt-item { display : inline-block; }

	.certif-link {
		padding		: 2rem;
		color		: #cad0d3;
		text-align	: center;
		transition	: color .3s ease-in-out;
	}
}
// END - LIST CERTIFICATION


// START CERTIFICATION
.certif-start-box {
	display				: table;
	width				: 100%;
	height				: 25.5rem;
	background-color	: $light-blue;
	text-align			: center;

	.certif-start-ctn {
		display			: table-cell;
		vertical-align	: middle;
		height			: 100%;
		width			: 100%;
		padding			: 1rem;
	}

	.certif-start-txt {
		display		: inline-block;
		font-size	: 1.8rem;
		line-height	: 1.3 ;
		color		: $grey;
		max-width	: 25rem;
	}

	.btn { margin-top	: 3rem; }
}
// END - START CERTIFICATION


// PREVIEW CERTIFICATION
.certif-preview-box {
	position		: relative;
	height			: 25.5rem;
	overflow		: hidden;
	border-radius	: .3rem;
	margin-bottom	: 3rem;
	
	&.is-with-editor,
	&.is-under-review,
	&.is-compliance-check,
	&.default {
		border : .1rem solid $grey;

		.certif-preview-status-box { background-color: $grey; }
	}

	&.is-available {
		background-color : $light-blue;

		.certif-preview-status-box { background-color: $green; }
	}

	&.is-error {
		border : .1rem solid $red;

		.certif-preview-date { color : $red; }

		.certif-preview-status-box { background-color : $red; }

		.certif-preview-link {
			padding-left : 6rem;

			&:before {
				content				: '';
				position			: absolute;
				left				: 3rem;
				top					: 50%;
				transform			: translateY(-50%);
				width				: 2.2rem;
				height				: 1.9rem;
				background-image	: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIxOSIgdmlld0JveD0iMCAwIDIyIDE5Ij4gIDxtZXRhZGF0YT48P3hwYWNrZXQgYmVnaW49Iu+7vyIgaWQ9Ilc1TTBNcENlaGlIenJlU3pOVGN6a2M5ZCI/Pjx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQwIDc5LjE2MDQ1MSwgMjAxNy8wNS8wNi0wMTowODoyMSAgICAgICAgIj4gICA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPiAgICAgIDxyZGY6RGVzY3JpcHRpb24gcmRmOmFib3V0PSIiLz4gICA8L3JkZjpSREY+PC94OnhtcG1ldGE+PD94cGFja2V0IGVuZD0idyI/PjwvbWV0YWRhdGE+PGRlZnM+ICAgIDxzdHlsZT4gICAgICAuY2xzLTEgeyAgICAgICAgZmlsbDogI2ZmZjsgICAgICAgIGZpbGwtcnVsZTogZXZlbm9kZDsgICAgICB9ICAgIDwvc3R5bGU+ICA8L2RlZnM+ICA8cGF0aCBpZD0id2FybmluZyIgY2xhc3M9ImNscy0xIiBkPSJNMTIzNzYsOTM2aDIybC0xMS0xOVptMTItM2gtMnYtMmgydjJabTAtNGgtMnYtNGgydjRaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTIzNzYgLTkxNykiLz48L3N2Zz4=);
			}
		}
	}

	.certif-preview-title {
		display		: block;
		font-size	: 2.4rem;
		padding		: 3rem 3rem 1rem;
	}

	.certif-preview-title,
	.certif-preview-id,
	.certif-preview-date {
		line-height	: 1.1;
		color		: $grey;
		margin		: 0;
	}

	.certif-preview-id {
		font-size	: 1.4rem;
		padding		: 1rem 3rem;
	}

	.certif-preview-date {
		font-size	: 1.4rem;
		padding		: 0 3rem 1rem;
	}

	.grade-circle {
		position	: absolute;
		right		: 1rem;
		bottom		: 6rem;
	}

	.certif-preview-status-box {
		position	: absolute;
		bottom		: 0;
		left		: 0;
		right		: 0;
		color		: $white;
	}

	.certif-preview-link {
		padding		: 0 3rem;
		color		: $white;
		line-height	: 5rem;
		transition	: opacity .3s ease-in-out;

		&:hover { opacity : .7; }
	}

	.certif-preview-icon {
		position	: absolute;
		right		: 1.5rem;
	}

	.certif-preview-txt,
	.certif-preview-error,
	.certif-preview-waiting,
	.certif-preview-progress {
		font-size	: 1.4;
		line-height	: 1.1;
		padding		: .5rem 3rem;
	}

	.certif-preview-txt {
		color : $grey;

		&.hide { display : none; }
	}

	.certif-preview-error {
		position	: relative;
		color		: $red;
		font-weight	: 700;

		.icon {
			margin-right : 1rem;

			&:before { color : $red; }
		}
	}
}
// END - PREVIEW CERTIFICATION



// PREVIEW CERTIFICATION
.certif-schema-wrapp { background-color : $white; }

.certif-schema-box {
	.certif-schema-list {
		position			: relative;
		float				: left;
		width				: calc(100% / 3 - 3.33333rem);
		padding				: .5rem;
		border-radius		: .3rem;
		background-color	: #034ecd;
		transition			: opacity .3s ease-in-out;

		&:hover { opacity : .9; }

		&.inactive {
			background-color	: #cad0d3;

			.certif-schema-logo .icon:before { color	: $light-blue; }
			.certif-schema-icon				 { display	: none; }
		}

		&:nth-child(2) {
			background-color : $blue;

			&:before,
			&:after {
				content				: "";
				position			: absolute;
				display				: block;
				width				: 1.5rem;
				height				: 12.5rem;
				top					: 50%;
				background-size		: cover;
				background-position	: 50% 50%;
				background-repeat	: no-repeat;
				background-image	: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTVweCIgaGVpZ2h0PSIxMjVweCI+ICAgIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgIGZpbGw9InJnYig3OCwgMjAzLCAxMzQpIiBkPSJNMS45OTIsMTI1LjAwMSBDMS45MjksMTI1LjAwMSAxLjg2NSwxMjQuOTk1IDEuODAxLDEyNC45ODQgQzEuMjUxLDEyNC44ODEgMC44OTAsMTI0LjM1OSAwLjk5NCwxMjMuODE3IEwxMi44MDAsNjIuNTQxIEwwLjk5NCwxLjI2NCBDMC44OTAsMC43MjIgMS4yNTEsMC4yMDAgMS44MDEsMC4wOTcgQzIuMzU5LC0wLjAwMiAyLjg4MiwwLjM1MSAyLjk4NiwwLjg5MiBMMTQuODYyLDYyLjU0MSBMMTQuODI4LDYyLjcyNyBMMi45ODYsMTI0LjE4OSBDMi44OTQsMTI0LjY2OCAyLjQ2OCwxMjUuMDAxIDEuOTkyLDEyNS4wMDEgWiIvPjwvc3ZnPg==);
			}

			&:before {
				left		: -3.2rem;
				transform	: translateY(-50%);
			}

			&:after {
				right		: -3.2rem;
				transform	: translateY(-50%) rotate(180deg);
			}
		}

		& + .certif-schema-list { margin-left : 5rem; }
	}

	.certif-schema-table {
		display		: table;
		width		: 100%;
		height		: 5rem;
		margin		: 9rem 0 6rem;
		text-align	: center;
	}

	.certif-schema-txt { 
		display			: table-cell;
		vertical-align	: middle;
		height			: 100%;
		color			: $white;
		font-size		: 1.8rem;
		line-height		: 1;
		font-weight		: 700;
	}

	.certif-schema-logo {
		position	: absolute;
		top			: 2rem;
		left		: 50%;
		transform	: translateX(-50%);

		.icon:before {
			font-size	: 4rem;
			color		: $green;
		}
	}

	.certif-schema-icon {
		position	: absolute;
		bottom		: 4rem;
		left		: 50%;
		transform	: translateX(-50%);

		.icon:before {
			font-size	: 1.5rem;
			color		: $white;
		}
	}
}
// END - PREVIEW CERTIFICATION
