.container,
.ctn,
.container-fluid,
.ctn-fluid {
	margin-left		: auto;
	margin-right	: auto;
	padding-left	: 1.5rem;
	padding-right	: 1.5rem;
}

.container,
.ctn { width : 150rem; }

.row {
	margin-left		: -1.5rem;
	margin-right	: -1.5rem;
}

@media (max-width: $lg-width) {
	.container,
	.ctn { width: 129rem; }
}

@media (max-width: $md-width) {
	.container,
	.ctn { width: 102.4rem; }
}

@media (max-width: $sm-width) {
	.container,
	.ctn { width: 75rem; }
}

@media (max-width: $xs-width) {
	.container,
	.ctn { width: auto }
}

@media (max-width: $xxs-width) {
	.row {
		margin-left		: -.7rem;
		margin-right	: -.7rem;
	}

	.container,
	.ctn,
	.container-fluid,
	.ctn-fluid {
		padding-left	: .7rem;
		padding-right	: .7rem;
	}
}

.col-xxs-1, .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, 
.col-xxs-2, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, 
.col-xxs-3, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, 
.col-xxs-4, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, 
.col-xxs-5, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, 
.col-xxs-6, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, 
.col-xxs-7, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, 
.col-xxs-8, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, 
.col-xxs-9, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, 
.col-xxs-10, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, 
.col-xxs-11, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, 
.col-xxs-12, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12, 
.xxs1, .xs1, .sm1, .md1, .lg1, 
.xxs2, .xs2, .sm2, .md2, .lg2, 
.xxs3, .xs3, .sm3, .md3, .lg3, 
.xxs4, .xs4, .sm4, .md4, .lg4, 
.xxs5, .xs5, .sm5, .md5, .lg5, 
.xxs6, .xs6, .sm6, .md6, .lg6,
.xxs7, .xs7, .sm7, .md7, .lg7, 
.xxs8, .xs8, .sm8, .md8, .lg8, 
.xxs9, .xs9, .sm9, .md9, .lg9, 
.xxs10, .xs10, .sm10, .md10, .lg10, 
.xxs11, .xs11, .sm11, .md11, .lg11, 
.xxs12, .xs12, .sm12, .md12, .lg12 {
	min-height		: .1rem;
	padding-left	: 1.5rem;
	padding-right	: 1.5rem;
	position		: relative;
}

.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12, 
.lg1,
.lg2,
.lg3,
.lg4,
.lg5,
.lg6,
.lg7,
.lg8,
.lg9,
.lg10,
.lg11,
.lg12 { float : left;  }

.col-lg-12,
.lg12 { width : 100%; }

.col-lg-11,
.lg11 { width : 91.66666667%; }

.col-lg-10,
.lg10 { width : 83.33333333%; }

.col-lg-9,
.lg9 { width : 75%; }

.col-lg-8,
.lg8 { width : 66.66666667%; }

.col-lg-7,
.lg7 { width : 58.33333333%; }

.col-lg-6,
.lg6 { width : 50%; }

.col-lg-5,
.lg5 { width : 41.66666667%; }

.col-lg-4,
.lg4 { width : 33.33333333%; }

.col-lg-3,
.lg3 { width : 25%; }

.col-lg-2,
.lg2 { width : 16.66666667%; }

.col-lg-1,
.lg1 { width : 8.33333333%; }

.pull-lg12 { right	: 100%; }
.pull-lg11 { right	: 91.66666667%; }
.pull-lg10 { right	: 83.33333333%; }
.pull-lg9  { right	: 75%; }
.pull-lg8  { right	: 66.66666667%; }
.pull-lg7  { right	: 58.33333333%; }
.pull-lg6  { right	: 50%; }
.pull-lg5  { right	: 41.66666667%; }
.pull-lg4  { right	: 33.33333333%; }
.pull-lg3  { right	: 25%; }
.pull-lg2  { right	: 16.66666667%; }
.pull-lg1  { right	: 8.33333333%; }
.pull-lg0  { right	: auto; }

.push-lg12  { left	: 100%; }
.push-lg11  { left	: 91.66666667%; }
.push-lg10  { left	: 83.33333333%; }
.push-lg9   { left	: 75%; }
.push-lg8   { left	: 66.66666667%; }
.push-lg7   { left	: 58.33333333%; }
.push-lg6   { left	: 50%; }
.push-lg5   { left	: 41.66666667%; }
.push-lg4   { left	: 33.33333333%; }
.push-lg3   { left	: 25%; }
.push-lg2   { left	: 16.66666667%; }
.push-lg1   { left	: 8.33333333%; }
.push-lg0   { left	: auto; }

.col-lg-offset-12,
.offset-lg12 { margin-left : 100%; }

.col-lg-offset-11,
.offset-lg11 { margin-left : 91.66666667%; }

.col-lg-offset-10,
.offset-lg10 { margin-left : 83.33333333%; }

.col-lg-offset-9,
.offset-lg9 { margin-left : 75%; }

.col-lg-offset-8,
.offset-lg8 { margin-left : 66.66666667%; }

.col-lg-offset-7,
.offset-lg7 { margin-left : 58.33333333%; }

.col-lg-offset-6,
.offset-lg6 { margin-left : 50%; }

.col-lg-offset-5,
.offset-lg5 { margin-left : 41.66666667%; }

.col-lg-offset-4,
.offset-lg4 { margin-left : 33.33333333%; }

.col-lg-offset-3,
.offset-lg3 { margin-left : 25%; }

.col-lg-offset-2,
.offset-lg2 { margin-left : 16.66666667%; }

.col-lg-offset-1,
.offset-lg1 { margin-left : 8.33333333%; }

.col-lg-offset-0,
.offset-lg0 { margin-left : 0%; }

.hidden-lg,
.lg-hidden { display : none !important; }

.visible-lg,
.lg-show { display : block !important; }

@media (max-width: $md-width) {
	.col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, 
	.md1, .md2, .md3, .md4, .md5, .md6, .md7, .md8, .md9, .md10, .md11, .md12 {
		float: left;
	}
	.col-md-12, .md12 {
		width: 100%;
	}
	.col-md-11, .md11 {
		width: 91.66666667%;
	}
	.col-md-10, .md10 {
		width: 83.33333333%;
	}
	.col-md-9, .md9 {
		width: 75%;
	}
	.col-md-8, .md8 {
		width: 66.66666667%;
	}
	.col-md-7, .md7 {
		width: 58.33333333%;
	}
	.col-md-6, .md6 {
		width: 50%;
	}
	.col-md-5, .md5 {
		width: 41.66666667%;
	}
	.col-md-4, .md4 {
		width: 33.33333333%;
	}
	.col-md-3, .md3 {
		width: 25%;
	}
	.col-md-2, .md2 {
		width: 16.66666667%;
	}
	.col-md-1, .md1 {
		width: 8.33333333%;
	}

	.pull-md12 {
		right: 100%;
	}
	.pull-md11 {
		right: 91.66666667%;
	}
	.pull-md10 {
		right: 83.33333333%;
	}
	.pull-md9 {
		right: 75%;
	}
	.pull-md8 {
		right: 66.66666667%;
	}
	.pull-md7 {
		right: 58.33333333%;
	}
	.pull-md6 {
		right: 50%;
	}
	.pull-md5 {
		right: 41.66666667%;
	}
	.pull-md4 {
		right: 33.33333333%;
	}
	.pull-md3 {
		right: 25%;
	}
	.pull-md2 {
		right: 16.66666667%;
	}
	.pull-md1 {
		right: 8.33333333%;
	}
	.pull-md0 {
		right: auto;
	}
	.push-md12 {
		left: 100%;
	}
	.push-md11 {
		left: 91.66666667%;
	}
	.push-md10 {
		left: 83.33333333%;
	}
	.push-md9 {
		left: 75%;
	}
	.push-md8 {
		left: 66.66666667%;
	}
	.push-md7 {
		left: 58.33333333%;
	}
	.push-md6 {
		left: 50%;
	}
	.push-md5 {
		left: 41.66666667%;
	}
	.push-md4 {
		left: 33.33333333%;
	}
	.push-md3 {
		left: 25%;
	}
	.push-md2 {
		left: 16.66666667%;
	}
	.push-md1 {
		left: 8.33333333%;
	}
	.push-md0 {
		left: auto;
	}
	.col-md-offset-12, .offset-md12 {
		margin-left: 100%;
	}
	.col-md-offset-11, .offset-md11 {
		margin-left: 91.66666667%;
	}
	.col-md-offset-10, .offset-md10 {
		margin-left: 83.33333333%;
	}
	.col-md-offset-9, .offset-md9 {
		margin-left: 75%;
	}
	.col-md-offset-8, .offset-md8 {
		margin-left: 66.66666667%;
	}
	.col-md-offset-7, .offset-md7 {
		margin-left: 58.33333333%;
	}
	.col-md-offset-6, .offset-md6 {
		margin-left: 50%;
	}
	.col-md-offset-5, .offset-md5 {
		margin-left: 41.66666667%;
	}
	.col-md-offset-4, .offset-md4 {
		margin-left: 33.33333333%;
	}
	.col-md-offset-3, .offset-md3 {
		margin-left: 25%;
	}
	.col-md-offset-2, .offset-md2 {
		margin-left: 16.66666667%;
	}
	.col-md-offset-1, .offset-md1 {
		margin-left: 8.33333333%;
	}
	.col-md-offset-0, .offset-md0 {
		margin-left: 0%;
	}
	.hidden-md, .md-hidden {
		display: none !important;
	}
	.visible-md, .md-block {
		display: block !important;
	}
}

@media (max-width: $sm-width) {
	.col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, 
	.sm1, .sm2, .sm3, .sm4, .sm5, .sm6, .sm7, .sm8, .sm9, .sm10, .sm11, .sm12 {
		float: left;
	}
	.col-sm-12, .sm12 {
		width: 100%;
	}
	.col-sm-11, .sm11 {
		width: 91.66666667%;
	}
	.col-sm-10, .sm10 {
		width: 83.33333333%;
	}
	.col-sm-9, .sm9 {
		width: 75%;
	}
	.col-sm-8, .sm8 {
		width: 66.66666667%;
	}
	.col-sm-7, .sm7 {
		width: 58.33333333%;
	}
	.col-sm-6, .sm6 {
		width: 50%;
	}
	.col-sm-5, .sm5 {
		width: 41.66666667%;
	}
	.col-sm-4, .sm4 {
		width: 33.33333333%;
	}
	.col-sm-3, .sm3 {
		width: 25%;
	}
	.col-sm-2, .sm2 {
		width: 16.66666667%;
	}
	.col-sm-1, .sm1 {
		width: 8.33333333%;
	}

	.pull-sm12 {
		right: 100%;
	}
	.pull-sm11 {
		right: 91.66666667%;
	}
	.pull-sm10 {
		right: 83.33333333%;
	}
	.pull-sm9 {
		right: 75%;
	}
	.pull-sm8 {
		right: 66.66666667%;
	}
	.pull-sm7 {
		right: 58.33333333%;
	}
	.pull-sm6 {
		right: 50%;
	}
	.pull-sm5 {
		right: 41.66666667%;
	}
	.pull-sm4 {
		right: 33.33333333%;
	}
	.pull-sm3 {
		right: 25%;
	}
	.pull-sm2 {
		right: 16.66666667%;
	}
	.pull-sm1 {
		right: 8.33333333%;
	}
	.pull-sm0 {
		right: auto;
	}
	.push-sm12 {
		left: 100%;
	}
	.push-sm11 {
		left: 91.66666667%;
	}
	.push-sm10 {
		left: 83.33333333%;
	}
	.push-sm9 {
		left: 75%;
	}
	.push-sm8 {
		left: 66.66666667%;
	}
	.push-sm7 {
		left: 58.33333333%;
	}
	.push-sm6 {
		left: 50%;
	}
	.push-sm5 {
		left: 41.66666667%;
	}
	.push-sm4 {
		left: 33.33333333%;
	}
	.push-sm3 {
		left: 25%;
	}
	.push-sm2 {
		left: 16.66666667%;
	}
	.push-sm1 {
		left: 8.33333333%;
	}
	.push-sm0 {
		left: auto;
	}
	.col-sm-offset-12, .offset-sm12 {
		margin-left: 100%;
	}
	.col-sm-offset-11, .offset-sm11 {
		margin-left: 91.66666667%;
	}
	.col-sm-offset-10, .offset-sm10 {
		margin-left: 83.33333333%;
	}
	.col-sm-offset-9, .offset-sm9 {
		margin-left: 75%;
	}
	.col-sm-offset-8, .offset-sm8 {
		margin-left: 66.66666667%;
	}
	.col-sm-offset-7, .offset-sm7 {
		margin-left: 58.33333333%;
	}
	.col-sm-offset-6, .offset-sm6 {
		margin-left: 50%;
	}
	.col-sm-offset-5, .offset-sm5 {
		margin-left: 41.66666667%;
	}
	.col-sm-offset-4, .offset-sm4 {
		margin-left: 33.33333333%;
	}
	.col-sm-offset-3, .offset-sm3 {
		margin-left: 25%;
	}
	.col-sm-offset-2, .offset-sm2 {
		margin-left: 16.66666667%;
	}
	.col-sm-offset-1, .offset-sm1 {
		margin-left: 8.33333333%;
	}
	.col-sm-offset-0, .offset-sm0 {
		margin-left: 0%;
	}
	.hidden-sm, .sm-hidden {
		display: none !important;
	}
	.visible-sm, .sm-show {
		display: block !important;
	}
}
@media (max-width: $xs-width) {
	.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, 
	.xs1, .xs2, .xs3, .xs4, .xs5, .xs6, .xs7, .xs8, .xs9, .xs10, .xs11, .xs12 {
		float: left;
	}
	.col-xs-12, .xs12 {
		width: 100%;
	}
	.col-xs-11, .xs11 {
		width: 91.66666667%;
	}
	.col-xs-10, .xs10 {
		width: 83.33333333%;
	}
	.col-xs-9, .xs9 {
		width: 75%;
	}
	.col-xs-8, .xs8 {
		width: 66.66666667%;
	}
	.col-xs-7, .xs7 {
		width: 58.33333333%;
	}
	.col-xs-6, .xs6 {
		width: 50%;
	}
	.col-xs-5, .xs5 {
		width: 41.66666667%;
	}
	.col-xs-4, .xs4 {
		width: 33.33333333%;
	}
	.col-xs-3, .xs3 {
		width: 25%;
	}
	.col-xs-2, .xs2 {
		width: 16.66666667%;
	}
	.col-xs-1, .xs1 {
		width: 8.33333333%;
	}
	.pull-xs12 {
		right: 100%;
	}
	.pull-xs11 {
		right: 91.66666667%;
	}
	.pull-xs10 {
		right: 83.33333333%;
	}
	.pull-xs9 {
		right: 75%;
	}
	.pull-xs8 {
		right: 66.66666667%;
	}
	.pull-xs7 {
		right: 58.33333333%;
	}
	.pull-xs6 {
		right: 50%;
	}
	.pull-xs5 {
		right: 41.66666667%;
	}
	.pull-xs4 {
		right: 33.33333333%;
	}
	.pull-xs3 {
		right: 25%;
	}
	.pull-xs2 {
		right: 16.66666667%;
	}
	.pull-xs1 {
		right: 8.33333333%;
	}
	.pull-xs0 {
		right: auto;
	}
	.push-xs12 {
		left: 100%;
	}
	.push-xs11 {
		left: 91.66666667%;
	}
	.push-xs10 {
		left: 83.33333333%;
	}
	.push-xs9 {
		left: 75%;
	}
	.push-xs8 {
		left: 66.66666667%;
	}
	.push-xs7 {
		left: 58.33333333%;
	}
	.push-xs6 {
		left: 50%;
	}
	.push-xs5 {
		left: 41.66666667%;
	}
	.push-xs4 {
		left: 33.33333333%;
	}
	.push-xs3 {
		left: 25%;
	}
	.push-xs2 {
		left: 16.66666667%;
	}
	.push-xs1 {
		left: 8.33333333%;
	}
	.push-xs0 {
		left: auto;
	}
	.col-xs-offset-12, .offset-xs12 {
		margin-left: 100%;
	}
	.col-xs-offset-11, .offset-xs11 {
		margin-left: 91.66666667%;
	}
	.col-xs-offset-10, .offset-xs10 {
		margin-left: 83.33333333%;
	}
	.col-xs-offset-9, .offset-xs9 {
		margin-left: 75%;
	}
	.col-xs-offset-8, .offset-xs8 {
		margin-left: 66.66666667%;
	}
	.col-xs-offset-7, .offset-xs7 {
		margin-left: 58.33333333%;
	}
	.col-xs-offset-6, .offset-xs6 {
		margin-left: 50%;
	}
	.col-xs-offset-5, .offset-xs5 {
		margin-left: 41.66666667%;
	}
	.col-xs-offset-4, .offset-xs4 {
		margin-left: 33.33333333%;
	}
	.col-xs-offset-3, .offset-xs3 {
		margin-left: 25%;
	}
	.col-xs-offset-2, .offset-xs2 {
		margin-left: 16.66666667%;
	}
	.col-xs-offset-1, .offset-xs1 {
		margin-left: 8.33333333%;
	}
	.col-xs-offset-0, .offset-xs0 {
		margin-left: 0%;
	}
	.hidden-xs, .xs-hidden {
		display: none !important;
	}
	.visible-xs, .xs-show {
		display: block !important;
	}
}
@media (max-width: $xxs-width) {
	.xxs1, .xs1, .sm1, .md1, .lg1, 
	.xxs2, .xs2, .sm2, .md2, .lg2, 
	.xxs3, .xs3, .sm3, .md3, .lg3, 
	.xxs4, .xs4, .sm4, .md4, .lg4, 
	.xxs5, .xs5, .sm5, .md5, .lg5, 
	.xxs6, .xs6, .sm6, .md6, .lg6,
	.xxs7, .xs7, .sm7, .md7, .lg7, 
	.xxs8, .xs8, .sm8, .md8, .lg8, 
	.xxs9, .xs9, .sm9, .md9, .lg9, 
	.xxs10, .xs10, .sm10, .md10, .lg10, 
	.xxs11, .xs11, .sm11, .md11, .lg11, 
	.xxs12, .xs12, .sm12, .md12, .lg12 {
		float: left;
		padding: 0 7px; 
	}
	.col-xxs-12, .xxs12 {
		width: 100%;
	}
	.col-xxs-11, .xxs11 {
		width: 91.66666667%;
	}
	.col-xxs-10, .xxs10 {
		width: 83.33333333%;
	}
	.col-xxs-9, .xxs9 {
		width: 75%;
	}
	.col-xxs-8, .xxs8 {
		width: 66.66666667%;
	}
	.col-xxs-7, .xxs7 {
		width: 58.33333333%;
	}
	.col-xxs-6, .xxs6 {
		width: 50%;
	}
	.col-xxs-5, .xxs5 {
		width: 41.66666667%;
	}
	.col-xxs-4, .xxs4 {
		width: 33.33333333%;
	}
	.col-xxs-3, .xxs3 {
		width: 25%;
	}
	.col-xxs-2, .xxs2 {
		width: 16.66666667%;
	}
	.col-xxs-1, .xxs1 {
		width: 8.33333333%;
	}
	.pull-xxs12 {
		right: 100%;
	}
	.pull-xxs11 {
		right: 91.66666667%;
	}
	.pull-xxs10 {
		right: 83.33333333%;
	}
	.pull-xxs9 {
		right: 75%;
	}
	.pull-xxs8 {
		right: 66.66666667%;
	}
	.pull-xxs7 {
		right: 58.33333333%;
	}
	.pull-xxs6 {
		right: 50%;
	}
	.pull-xxs5 {
		right: 41.66666667%;
	}
	.pull-xxs4 {
		right: 33.33333333%;
	}
	.pull-xxs3 {
		right: 25%;
	}
	.pull-xxs2 {
		right: 16.66666667%;
	}
	.pull-xxs1 {
		right: 8.33333333%;
	}
	.pull-xxs0 {
		right: auto;
	}
	.push-xxs12 {
		left: 100%;
	}
	.push-xxs11 {
		left: 91.66666667%;
	}
	.push-xxs10 {
		left: 83.33333333%;
	}
	.push-xxs9 {
		left: 75%;
	}
	.push-xxs8 {
		left: 66.66666667%;
	}
	.push-xxs7 {
		left: 58.33333333%;
	}
	.push-xxs6 {
		left: 50%;
	}
	.push-xxs5 {
		left: 41.66666667%;
	}
	.push-xxs4 {
		left: 33.33333333%;
	}
	.push-xxs3 {
		left: 25%;
	}
	.push-xxs2 {
		left: 16.66666667%;
	}
	.push-xxs1 {
		left: 8.33333333%;
	}
	.push-xxs0 {
		left: auto;
	}
	.col-xxs-offset-12, .offset-xxs12 {
		margin-left: 100%;
	}
	.col-xxs-offset-11, .offset-xxs11 {
		margin-left: 91.66666667%;
	}
	.col-xxs-offset-10, .offset-xxs10 {
		margin-left: 83.33333333%;
	}
	.col-xxs-offset-9, .offset-xxs9 {
		margin-left: 75%;
	}
	.col-xxs-offset-8, .offset-xxs8 {
		margin-left: 66.66666667%;
	}
	.col-xxs-offset-7, .offset-xxs7 {
		margin-left: 58.33333333%;
	}
	.col-xxs-offset-6, .offset-xxs6 {
		margin-left: 50%;
	}
	.col-xxs-offset-5, .offset-xxs5 {
		margin-left: 41.66666667%;
	}
	.col-xxs-offset-4, .offset-xxs4 {
		margin-left: 33.33333333%;
	}
	.col-xxs-offset-3, .offset-xxs3 {
		margin-left: 25%;
	}
	.col-xxs-offset-2, .offset-xxs2 {
		margin-left: 16.66666667%;
	}
	.col-xxs-offset-1, .offset-xxs1 {
		margin-left: 8.33333333%;
	}
	.col-xxs-offset-0, .offset-xxs0 {
		margin-left: 0%;
	}
	.hidden-xxs, .xxs-hidden {
		display: none !important;
	}
	.visible-xxs, .xxs-show {
		display: block !important;
	}
}

.clearfix:before, 
.clearfix:after,
.container:before,
.container:after,
.ctn:before,
.ctn:after,
.container-fluid:before,
.container-fluid:after,
.ctn-fluid:before,
.ctn-fluid:after,
.row:before,
.row:after,
.small-row:before,
.small-row:after {
	content: " ";
	display: table;
}
.clearfix:after,
.container:after,
.container-fluid:after,
.ctn:after,
.ctn-fluid:after,
.row:after,
.small-row:before,
.small-row:after {
	clear: both;
}

.clear { clear: both }
.hidden, .unvisible { display: none }

.pull-right { float: right }

.cover { background-position: center center; -webkit-background-size: cover; -moz-background-size: cover; background-size: cover }
.contain { -webkit-background-size: contain; -moz-background-size: contain; background-size: contain }



.img-responsive, .imgr {
	display: inline-block;
	height: auto;
	max-width: 100%;
}

.row::before, .row::after { flex-basis: 0; order: 1; }
.clearfix::before, .clearfix::after { flex-basis: 0; order: 1; }

.text-center { text-align: center }
.text-right { text-align: right }
.text-left { text-align: left }