// WIDTHS
$lg-width		: 1500px;
$md-width		: 1320px;
$sm-width		: 992px;
$xs-width		: 768px;
$xxs-width		: 480px;
$xxxs-width		: 360px;

// COLORS
$black			: #000;
$blue			: #034ecd;
$btn-inactive	: #d3d3d3;
$dark-blue		: #090b44;
$green			: #4ecb86;
$grey			: #222;
$light-blue		: #f1f2fa;
$light-grey		: #f2f2f2;
$red			: #fc3845;
$skin			: #ff7877;
$white			: #fff;

// FONTS
$main-font		: 'IBM Plex Sans', sans-serif;
