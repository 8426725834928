/*************************************************************/
/**************          ==  FOOTER  ==         **************/
/*************************************************************/
.footer-main {
	position			: absolute;
	bottom				: 0;
	left				: 0;
	right				: 0;
	width				: 100%;
	background-color	: $black;

	.footer-wrap1 { float	: left; }
	.footer-wrap2 { float	: right; }

	.menu-wrapp  { 
		display	: table;
		height	: 10rem;
	}

	.menu-box {
		display			: table-cell;
		vertical-align	: middle;
		position		: relative;
		height			: 100%;

		// To Add a Separtor Line After Fourth Link
		&:nth-child(5) {
			padding-left : 4rem;

			&:before {
				content				:"";
				position			: absolute;
				top					: 50%;
				transform			: translateY(-50%);
				display				: inline-block;
				height				: 2.6rem;
				width				: .1rem;
				background-color	: #4d4d4d;
			}

			.footer-link { padding-left : 2rem; }
		}
	}

	.menu-link {
		opacity			: .7;
		padding			: 1.5rem;
		font-size		: 1.8rem;
		line-height		: 1;
		color			: $white;
		transition		: opacity .3s ease-in-out;
		cursor			: pointer;

		&:hover { opacity : 1; }
	}

	.icon {
		display		: block;
		font-size	: 3rem;

		&.icon-cascad_logo_1coul {
			margin-top : -1.1rem;

			&:before { color : $white; }
		}
	}
}

.footer-mobile { display : none; }


@media only screen and (max-width: 992px) {
	body { padding-bottom : 0; } // Reset the place for footer position absolute.

	.footer-main { display : none; }
}