/*************************************************************/
/**************          ==  BTN  ==                **********/
/*************************************************************/
.btn {
	display			: inline-block;
	text-align		: center;
	border			: none;
	border-radius	: .3rem;
	font-size		: 1.8rem;
	line-height		: 5rem;
	height			: 5rem;
	font-weight		: 500;
	vertical-align	: middle;
	padding			: 0;
	cursor			: pointer;

	.btn-link {
		display		: inline-block;
		padding		: 0 3rem;
		min-width	: 28rem;
		transition	: opacity .3s ease-in-out;

		&.btn-small { min-width	: 17rem; }
	}

	.icon {
		display		: inline-block;
		margin-left	: 2.5rem;
	}

	&:hover .btn-link { opacity : .6; }
}

.btn-blue {
	background-color : $blue;

	.btn-link { color : $white; }
}

.btn-black {
	background-color : $black;

	.btn-link { color : $white; }
}

.btn-white {
	background-color : $white;

	.btn-link { color : $black; }
}

.btn-is-inactive {
	background-color : $btn-inactive;

	.btn-link { color : $white; }
}

.btn-back {
	.icon     { margin-left	: 0; }
	.btn-link { color		: $white; }
}

.btn-clear-box {
	position : relative;

	.btn-clear {
		position	: absolute;
		right		: 1rem;
		top			: 50%;
		transform	: translateY(-50%);
		padding		: 1rem;
		cursor		: pointer;
	}
}