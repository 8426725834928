// To use REM unit
html {
	box-sizing	: border-box;
	font-size	: 62.5%; 
	height		: 100%;
	overflow-x	: hidden;
}

//  Apply a natural box layout model to all elements,
//  but allowing components to change.
*,
*:before,
*:after { box-sizing : inherit; }

body {
	font-family		: $main-font;
	font-size		: 1.6rem;
	line-height		: 1;
	min-height		: calc(100% - 10rem);
	padding-bottom	: 10rem; // A place for "sticky" footer menu
	position		: relative;
}

a {
	cursor			: pointer;
	display			: block;
	text-decoration	: none;
}

input,
select,
textarea { font-family : $main-font; }

// Adjust page content for sticky menu
.main-content-desktop { display : block; margin : 10rem 0 0; }

::-webkit-input-placeholder { color : rgba(34, 34, 34, .5); font-size : 1.6rem; }
::-moz-placeholder          { color : rgba(34, 34, 34, .5); font-size : 1.6rem; }
:-ms-input-placeholder      { color : rgba(34, 34, 34, .5); font-size : 1.6rem; }
:-moz-placeholder           { color : rgba(34, 34, 34, .5); font-size : 1.6rem; }


// STYLISE BULLETS ON PLACEHOLDER INPUT PASWWORD
input[type=password]::-webkit-input-placeholder { color : rgba(34, 34, 34, .5); font-size : 2.9rem; letter-spacing : -.1rem; }
input[type=password]::-moz-placeholder          { color : rgba(34, 34, 34, .5); font-size : 2.9rem; letter-spacing : -.1rem; }
input[type=password]:-ms-input-placeholder      { color : rgba(34, 34, 34, .5); font-size : 2.9rem; letter-spacing : -.1rem; }
input[type=password]:-moz-placeholder           { color : rgba(34, 34, 34, .5); font-size : 2.9rem; letter-spacing : -.1rem; }



// .fix-width { min-height : 128rem; }